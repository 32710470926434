import React, { useState, useEffect } from 'react';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import * as moment from "moment";

export default function Calendrier(){
    const [mark, setMark] = useState([]);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch('/dates.json');
          const data = await response.json();
          setMark(data);
          console.log(mark)
        } catch (error) {
          console.error('Error fetching dates:', error);
        }
      };
    
      fetchData();
    }, []);

    const [value, onChange] = useState(new Date());
  return <>
    <div>
      <Calendar 
      onChange={onChange} 
      value={value} 
      tileClassName={({ date, view }) => {
        if(mark.find(x=>x===moment(date).format("DD-MM-YYYY"))){
         return  'booked'
        }
      }}
  
  
      tileDisabled={({ date }) => date.getDay() === 0}
  
      /*maxDate={new Date(2020, 1, 0)}</div>*/
       minDate={
        new Date()
      }
      />

    </div>
</>
}
