import { createRoot } from 'react-dom/client'
import App from './App.jsx'
import './style.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import ReactDOM from 'react-dom';
import MessengerCustomerChat from 'react-messenger-customer-chat';

const root = createRoot(document.querySelector('#root'))

root.render(
    <App>
    </App>
)
