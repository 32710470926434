import React, { useState } from 'react';

export default function Header()
{
    const [isNavOpen, setIsNavOpen] = useState(false);

    const handleNavToggle = () => {
      setIsNavOpen(!isNavOpen);
    };
  
    return <header>
        <nav>
            <div className="logo">
                <h1>Studio 51</h1>
            </div>
            <ul className={`ulmenu ${isNavOpen ? 'menuactive' : ''}`}>
                <li onClick={handleNavToggle}><a href="/">Accueil</a></li>
                <li onClick={handleNavToggle}><a href="#services">Services</a></li>
                <li onClick={handleNavToggle}><a href="#contact">Contact</a></li>
                <li onClick={handleNavToggle}><a href="#disponibilites">Disponibilités</a></li>
            </ul>
            <div className="menu" onClick={handleNavToggle}>
                <div className={`menu__svg ${isNavOpen ? 'open' : ''}`}></div>
            </div>
        </nav>
    </header>
}