import Map from './Map.jsx'
import Calendrier from './Calendrier.jsx'

export default function Footer()
{
    return <footer>
        <div className="footer">
            <div className="anchor contact" id="contact">
            <h2>Contact</h2>
            <ul>
                <li>+32 479 49 49 32</li>
                <li>francoisegenon@yahoo.com</li>
                <li className="adress">
                    Résidence Eden Park <br />
                    Chemin des chasseurs , 40  <br />
                    83150 Bandol

                </li>
            </ul>
            </div>
            <div className="map">
                <Map />
            </div>
        </div>
        <div className="anchor footer_bottom" id="disponibilites">
            <h2>Disponibilités</h2>
            <Calendrier />
        </div>
    </footer>
}