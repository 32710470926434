import React from "react";
import GoogleMapReact from 'google-map-react';

const AnyReactComponent = ({ text }) => <div className="red">{text}</div>;

export default function Map(){
  const defaultProps = {
    center: {
      lat: 43.14066041200387, 
      lng: 5.728960083581829
    },
    zoom: 15
  };

  return (
    // Important! Always set the container height explicitly
    <div style={{ height: '500px', width: '100%' }}>
      <GoogleMapReact
        bootstrapURLKeys={{ key: "AIzaSyCW7oU6Ns67g3VrssmBwFkyy7-PjD7eUlQ" }}
        defaultCenter={defaultProps.center}
        defaultZoom={defaultProps.zoom}
      >
        <AnyReactComponent
          lat={43.14061344}
          lng={5.728970}
        />
      </GoogleMapReact>
    </div>
  );
}
