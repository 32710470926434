import React from "react";
import Slider from "react-slick";

export default function SimpleSlider() {
  var settings = {
    arrows: true,
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  return (
    <div className="container">
    <Slider {...settings}>
      <div>
        <h3>« Studio magnifique et très bien équipé à deux pas de la crique, je ne peux que recommander »</h3>
      </div>
      <div>
        <h3>«Un séjour relaxant et magique ! Cet appartement à proximité de la mer offre le cadre idéal pour des vacances inoubliables. »</h3>
      </div>
      <div>
        <h3>«Propreté impeccable, décoration élégante. Nous avons adoré chaque instant passé dans cet appartement à deux pas de la mer. »</h3>
      </div>
      <div>
        <h3>« Si vous cherchez un endroit paisible pour vous ressourcer, ne cherchez plus. Cet appartement est tout simplement parfait. »</h3>
      </div>
      <div>
        <h3>« Très bel appartement, ambiance apaisante, proche de la mer ! Un véritable bijou caché. »</h3>
      </div>
      <div>
        <h3>« Une escapade inoubliable ! Cet appartement offre le mélange parfait entre confort et beauté naturelle. »</h3>
      </div>
    </Slider>
    </div>
  );
}
