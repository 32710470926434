import Clicker from './Clicker.jsx'
import { useMemo, useState } from 'react'
import People from './People.jsx'
import Header from './Header.jsx'
import Intro from './Intro.jsx'
import Testimonial from './Testimonial.jsx'
import Custompano from './Custompano.jsx'
import Services from './Services.jsx'
import Footer from './Footer.jsx'

export default function App()
{

    return <>
        <Header />
        <main>
            <Intro />
            <Testimonial />
            <Custompano />

            <Services />
        </main>
        <Footer  id="#footer" /> 
        {/* <People /> */}
    </>
}