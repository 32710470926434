import React from "react";
import Slider from "react-slick";

export default function Services(){
var settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 6000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [{
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          autoplaySpeed: 3000,
        }
    }]
}
{
    return <div>
        <div> 
            <h2 className="anchor services">Services</h2>
        </div>
        <ul className="services--list">
        <div id="services"></div>
            <li className="icone">
                <i>
                    <img className="img-fluid" 
                    src={`${process.env.PUBLIC_URL}/icons/kitchen.png`} 
                    alt="logo"/>
                </i>
                <h3>Kitchenette</h3>
            </li>
            <li className="icone">
                <i>
                    <img className="img-fluid" 
                    src={`${process.env.PUBLIC_URL}/icons/washing-machine.png`} 
                    alt="logo"/>
                </i>
                <h3>Machine à laver</h3>
            </li>
            <li className="icone">
                <i>
                    <img className="img-fluid" 
                    src={`${process.env.PUBLIC_URL}/icons/towels.png`} 
                    alt="logo"/>
                </i>
                <h3>Linges</h3>
            </li>
            <li className="icone">
                <i>
                    <img className="img-fluid" 
                    src={`${process.env.PUBLIC_URL}/icons/parking.png`} 
                    alt="logo"/>
                </i>
                <h3>Parking</h3>
            </li>
            <li className="icone">
                <i>
                    <img className="img-fluid" 
                    src={`${process.env.PUBLIC_URL}/icons/wifi.png`} 
                    alt="logo"/>
                </i>
                <h3>Wifi</h3>
            </li>
        </ul>
        <div className="photos">
            <Slider {...settings}>
            <div>
                <img src={`${process.env.PUBLIC_URL}/img/IMG_7841-min.JPG`} />
            </div>
            <div>
                <img src={`${process.env.PUBLIC_URL}/img/image0.jpeg`} />
            </div>
            <div>
                <img src={`${process.env.PUBLIC_URL}/img/IMG_7865-min.JPG`} />
            </div>
            <div>
                <img src={`${process.env.PUBLIC_URL}/img/IMG_7870-min.JPG`} />
            </div>
            <div>
                <img src={`${process.env.PUBLIC_URL}/img/IMG_7860-min.JPG`} />
            </div>
            <div>
                <img src={`${process.env.PUBLIC_URL}/img/IMG_7883-min.JPG`} />
            </div>
            <div>
                <img src={`${process.env.PUBLIC_URL}/img/IMG_7885-min.JPG`} />
            </div>
            </Slider>
        </div>
    </div>
}
}