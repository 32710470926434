import React, { useRef, useState } from 'react';

export default function Custompano()
{
        const sliderRef = useRef(null);
        const [mouseDown, setMouseDown] = useState(false);
        const [startX, setStartX] = useState(0);
        const [scrollLeft, setScrollLeft] = useState(0);

        const [touchStartX, setTouchStartX] = useState(0);
        
        const startDragging = (e) => {
          setMouseDown(true);
          setStartX(e.pageX - sliderRef.current.offsetLeft);
          setScrollLeft(sliderRef.current.scrollLeft);
        };
      
        const stopDragging = (event) => {
          setMouseDown(false);
        };
      
        const handleMouseMove = (e) => {
          e.preventDefault();
          if (!mouseDown) {
            return;
          }
          const x = e.pageX - sliderRef.current.offsetLeft;
          const scroll = x - startX;
          sliderRef.current.scrollLeft = scrollLeft - scroll;
        };

        const startDragging2 = (e) => {
          if (e.touches.length === 1) {
            setTouchStartX(e.touches[0].pageX - sliderRef.current.offsetLeft);
            setScrollLeft(sliderRef.current.scrollLeft);
          }
        };
        
        const stopDragging2 = (event) => {
          setTouchStartX(0);
        };

        const handleTouchMove = (e) => {
          if (e.touches.length === 1) {
            const x = e.touches[0].pageX - sliderRef.current.offsetLeft;
            const scroll = x - touchStartX;
            sliderRef.current.scrollLeft = scrollLeft - scroll;
          }
        };
        
    return <div className='container-parent'>
          <div  
              className="parent"
              ref={sliderRef}
              onTouchStart={startDragging2}
              onTouchEnd={stopDragging2}
              onTouchMove={handleTouchMove}
              onMouseDown={startDragging}
              onMouseUp={stopDragging}
              onMouseMove={handleMouseMove}
           
        >
            <img className="child" src={`${process.env.PUBLIC_URL}/360/361.jpeg`} />
        </div>
    </div>
}