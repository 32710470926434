export default function Intro()
{
    return <>
        <div>
        <div className="intro">
                Bandol <div className="i1"></div> authentique station 
                <div className="i2"></div>
                balnéaire 
                du littoral
                <div className="i3"></div> 
                méditerranéen et du Var
            </div>
        </div>
        <div className="cards">
            <div className="cards__el1"></div>
            <div className="cards__el2"></div>
            <div className="cards__el3"></div>
            <div className="cards__el4">
                <p>
                Studio avec terrasse, place de parking et petit jardin situé dans le quartier de Pierreplane aux portes du sentier du littoral et à 600m de la crique de l’Anglaise . Il comprend une kitchenette, une salle de bain avec machine à laver, un coin avec lits superposés et un lit escamotable. 
                </p>

            </div>
        </div>
    </>
}